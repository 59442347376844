/* Home.css */
.background {
  background-color: black;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  position: relative;

}

/* .container {
  min-width: 70vw;
  max-width: 1400px;

  margin: 0;

} */



.overlay-content {
  /* position: absolute; */
  /* top: 12rem; */
  left: 0;
  width: 100%;
  pointer-events: none;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.overlay-content .container {
  pointer-events: auto;
}

.overlay-content img {
  cursor: pointer;
}

d-flex {
  padding: 0;
}

h5 {
  padding-top: 10px;
  text-decoration: none;
}

a,
a h5 {
  text-decoration: none !important;
}

a:hover h5 {
  text-decoration: underline !important;
  color: white;
}

video,
img {
  margin: 0;
  padding: 0;
  display: block;
}

@media (max-width: 768px) {
  .homecontent {
    padding-top: 3rem;
  }
}


@media (max-width: 1024px) {
  .homecontent {
    padding-top: 2rem !important;
  }


}

@media (max-width: 1440px) {
  .homecontent {
    padding-top: 3rem;
  }

}