/* AnimatedText.css */

.animated-text {
    font-size: 2rem;
    /* Fixed font size */
    letter-spacing: 5px;
    /* Initial larger letter spacing */
    color: #fff;
    opacity: 0;
    /* Start hidden for fade-in effect */
    transition: letter-spacing 1s ease-in-out, opacity 1s ease-in-out;
}

.animated-text.animate {
    animation: letterSpacingBounce 1s forwards;
}

/* Keyframes for letter-spacing animation */
@keyframes letterSpacingBounce {
    0% {
        letter-spacing: 5px;
        /* Start with large spacing */
        opacity: 0;
    }

    30% {
        letter-spacing: 1px;
        /* Reduce to smaller spacing */
        opacity: 1;
    }

    100% {
        letter-spacing: 2px;
        /* Set to middle/normal spacing */
        opacity: 1;
    }
}

/* Optional: Responsive Design for Smaller Screens */
@media (max-width: 600px) {
    .animated-text {
        font-size: 1.5rem;
        /* Adjust font size */
        letter-spacing: 3px;
        /* Adjust initial letter spacing */
    }

    @keyframes letterSpacingBounce {
        0% {
            letter-spacing: 3px;
            opacity: 0;
        }

        30% {
            letter-spacing: 0.5px;
            opacity: 1;
        }

        100% {
            letter-spacing: 1.5px;
            opacity: 1;
        }
    }
}