/* About.css */

.links line {
  stroke: #b4b4b4;

  /* stroke-opacity: 0.5; */
}

.nodes circle {
  fill: #b4b4b4;
  stroke-width: 0;
  /* fill-opacity: 0.5; */
}

.labels text {
  font-family: 'Neue Haas Unica W1G', sans-serif;
  font-size: 1.2rem;
  fill: white;
  /* Remove or comment out the following line */
  /* fill-opacity: 0.7; */
  pointer-events: none;
}

.header-label {
  /* font-weight: bold; */
  font-size: 1.2rem;
  font-family: 'Neue Haas Unica W1G', sans-serif;
  fill-opacity: 1 !important;
  fill: #b4b4b4;

}


.container {
  padding-top: 6rem;
}